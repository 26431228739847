import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { MOBILE_HEADER_MAX_WIDTH } from '../common/constants';
import DesktopMenu from './menu/desktop-menu';
import MobileMenu from './menu/mobile-menu' ;
import { getTotalQtyInCart } from '../common/cart';

const CartCounter = styled.span`
  background: #ffcc00;
  border-radius: 12px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  height: 14px;
  min-width: 14px;
  position: absolute;
  top: -9px;
  right: -12px;
  z-index: 1;
`;

export default () => {
  const [fadeOutCartCounter, setFadeOutCartCounter] = useState(false);
  const [cartTotalQty, setCartTotalQty] = useState(getTotalQtyInCart());

  useEffect(() => {
    const onAddToCart = e => {
      setCartTotalQty(e.detail.count);
      setFadeOutCartCounter(true);
      setTimeout(() => {
        setFadeOutCartCounter(false);
      }, 1000);
    };

    window.addEventListener('cart:add', onAddToCart);
    return () => {
      // clean up the event handler when the component unmounts
      window.removeEventListener('cart:add', onAddToCart);
    };
  }, []);

  return (
    <header css={css`
      background: #f9f9f9;
      border-bottom: 1px solid #eee;
      align-items: center;
      display: flex;
      height: calc(72px - 2rem);
      margin-bottom: 2rem;
      padding: 1rem;
      position: sticky;
      top: 0;
      z-index: 3;

      @media (max-width: 640px) {
        header {
          padding-left: 0.5rem;
        }
      }
    `}>
      <MobileMenu></MobileMenu>

      <DesktopMenu></DesktopMenu>

      <Link to="/"
        css={css`
          border: none;
          margin: 0 auto;
        `}
      >
        <img src="/static/paste-wax-logo.png" alt="Paste Wax Logo"
          css={css`
            display: block;
          `}
        />
      </Link>

      <Link to="/cart" css={css`
        border: none;
        margin-top: 6px;

        &:hover .cart-txt {
          border-bottom: 2px solid #ffcc00;
        }
      `}>
        <div css={css`
          position: relative;
          display: inline-block;
          margin-right: 22px;
          vertical-align: middle;
          top: -1px;
        `}>
          <svg viewBox="0 0 27 24" role="presentation"
            css={css`
              height: 24px;
              width: 27px;
            `}
          >
            <g transform="translate(0 1)" strokeWidth="2" stroke="currentColor" fill="none" fillRule="evenodd">
              <circle strokeLinecap="square" cx="11" cy="20" r="2"></circle>
              <circle strokeLinecap="square" cx="22" cy="20" r="2"></circle>
              <path d="M7.31 5h18.27l-1.44 10H9.78L6.22 0H0"></path>
            </g>
          </svg>

          <CartCounter>{cartTotalQty}</CartCounter>
          <CartCounter className={fadeOutCartCounter ? 'fade-out' : ''}
            css={css`
              /* transition: all 300ms; */
              z-index: 2;

              &.fade-out {
                transition: all 300ms;
                transform: scale(3);
                opacity: 0;
              }
            `}
          >
            {cartTotalQty}
          </CartCounter>
        </div>

        <span className="cart-txt" css={css`
          @media (max-width: ${MOBILE_HEADER_MAX_WIDTH}px) {
            display: none;
          }
        `}>
          Cart
        </span>
      </Link>
    </header>
  );
};
