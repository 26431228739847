import 'fontsource-open-sans/300.css';
import 'fontsource-open-sans/400.css';
import 'fontsource-open-sans/600.css';
import React from 'react';
import { Global, css } from '@emotion/core';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/use-siteMetadata';
import Header from '../components/header';
import Footer from '../components/footer';
import { getCanonicalUrl } from '../common/utils';

const Layout = ({ children, title, description, slug }) => {
  const { title: defaultTitle, description: defaultDescription, siteUrl } = useSiteMetadata();

  return (
    <div css={css`
      margin: 0 auto;
      min-width: 320px;
      max-width: 1480px;
      padding: 0 1rem 4rem 1rem;

      @media(max-width: 640px) {
        /* font-size: 18px; */
        padding: 0 0 4rem 0;
      }
    `}>
      <Global styles={css`
        html, body {
          margin: 0;
        }

        body {
          background: #f9f9f9;
          border-bottom: 6px solid #fc0;
          color: #333;
          font-family: "Open Sans", sans-serif;
          font-weight: 300;
          line-height: 1.8;

          @media(max-width: 800px) {
            /* font-size: 18px; */
          }
        }

        a {
          border-bottom: 1px solid #fc0;
          color: #666;
          font-weight: 400;
          padding-bottom: 2px;
          text-decoration: none;

          &:hover {
            border-color: transparent;
          }
        }

        h1, h2 {
          border-bottom: 1px solid #fc0;
          color: #888;
          font-size: 2rem;
          font-weight: 300;
        }
        
        h3 {
          color: #888;
          font-weight: 400;
        }

        .hide {
          display: none !important;
        }

        #logo-img {
          display: block;
        }

        #icon-hamburger {
          color: #999;
          height: 16px;
          width: 26px;
        }
        #icon-cross {
          color: #999;
          height: 19px;
          width: 19px;
        }

        #contact {
          padding-left: 1rem;
          width: 50%;
        }

        #contact-heading {
          border: none;
          margin: 0 0 1rem 0;
        }

        #email-heading {
          margin-bottom: 0;
        }
      `} />

      <Helmet>
        <html lang="en" />
        <title>{title || defaultTitle}</title>
        <meta name="description" content={description || defaultDescription} />
        {typeof slug === 'string' && <link rel="canonical" href={getCanonicalUrl(siteUrl, slug)} />}
      </Helmet>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
