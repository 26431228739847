import React from 'react';
import { Link } from "gatsby";
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { MOBILE_HEADER_MAX_WIDTH } from '../../common/constants';
import MenuColumn from './column';
import MenuHeading from './heading';
import BRANDS from '../../../data/brands.json';

const MenuContainerItem = styled.li`
  position: relative;

  &:hover > .menu {
    opacity: 1;
    visibility: visible;
  }
`;

const NavItem = styled.span`
  border: none;
  color: #888;
  cursor: pointer;
  font-weight: 400;
  margin-right: 1rem;
  padding: 23px 0;

  &:hover {
    color: #666;
  }

  /* &.active {
    border-bottom: 2px solid #fc0;
    color: #666;
  } */

  &:first-of-type {
    margin-left: 0.5rem;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const SubMenuDesktop = styled.div`
  display: flex;
  position: absolute;
  background: #555;
  left: 0;
  top: calc(100% + 5px);
  width: max-content;
  padding: 10px 20px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  z-index: 1;
`;

const MenuColumnDesktop = styled(MenuColumn)`
  margin-left: 20px;

  &:first-of-type {
    margin-left: 0;
  }
`;

export default () => {
  return (
    <nav>
      <ul css={css`
        display: flex;
        list-style: none;
        padding: 0;

        @media (max-width: ${MOBILE_HEADER_MAX_WIDTH}px) {
          display: none;
        }
      `}>
        <MenuContainerItem>
          <NavItem>Categories</NavItem>
          <SubMenuDesktop className="menu">
            <MenuColumnDesktop>
              <MenuHeading>Washing</MenuHeading>
              <Link activeClassName="active" to="/category/decontamination">Decomtamination</Link>
              <Link activeClassName="active" to="/category/shampoos">Shampoos</Link>
              <Link activeClassName="active" to="/category/towels-mitts-brushes">Towels</Link>
            </MenuColumnDesktop>

            <MenuColumnDesktop>
              <MenuHeading>Exterior</MenuHeading>
              <Link activeClassName="active" to="/category/coatings">Coatings</Link>
              <Link activeClassName="active" to="/category/quick-detailers-spray-waxes">Quick Detailers &amp; Spray Waxes</Link>
              <Link activeClassName="active" to="/category/waxes-sealants">Waxes &amp; Sealants</Link>
              <Link activeClassName="active" to="/category/glass">Glass</Link>
              <Link activeClassName="active" to="/category/exterior-trim-rubber">Plastic &amp; Rubber</Link>
              <Link activeClassName="active" to="/category/headlight">Headlight Restoration &amp; Protection</Link>
              <Link activeClassName="active" to="/category/metal-polishes">Metal Polishes</Link>
              <Link activeClassName="active" to="/category/wheel-tire-cleaners">Wheel Cleaners</Link>
            </MenuColumnDesktop>

            <MenuColumnDesktop>
              <MenuHeading>Interior</MenuHeading>
              <Link activeClassName="active" to="/category/interior-cleaners">Interior Cleaners</Link>
              <Link activeClassName="active" to="/category/interior-protectants">Interior Protectants</Link>
              <Link activeClassName="active" to="/category/leather-fabric-care">Leather</Link>
            </MenuColumnDesktop>

            <MenuColumnDesktop>
              <MenuHeading>Polishing</MenuHeading>
              <Link activeClassName="active" to="/category/compounds-polishes">Compounds &amp; Polishes</Link>
            </MenuColumnDesktop>

            <MenuColumnDesktop>
              <MenuHeading>Repair</MenuHeading>
              <Link activeClassName="active" to="/category/repair">Paint Touchup</Link>
            </MenuColumnDesktop>
          </SubMenuDesktop>
        </MenuContainerItem>

        <MenuContainerItem>
          <NavItem>Brands</NavItem>
          <SubMenuDesktop className="menu">
            <MenuColumnDesktop>
              {Object.entries(BRANDS).map(([key, brand]) => (
                <Link activeClassName="active" key={key} to={`/brand/${key}`}>{brand.title}</Link>
              ))}
            </MenuColumnDesktop>
          </SubMenuDesktop>
        </MenuContainerItem>

        <MenuContainerItem>
          <Link activeClassName="active" to={`/whats-new`} css={css`
            border: none;

            &.active > span {
              border-bottom: 1px solid #fc0
            }
          `}>
            <NavItem>What's New</NavItem>
          </Link>
        </MenuContainerItem>
      </ul>
    </nav>
  );
};
