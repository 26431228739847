export function getLegacyImage(imgSrc) {
  const endIndex = imgSrc.lastIndexOf('.webp');
  if (endIndex > -1) return imgSrc.substring(0, endIndex) + '.jpg';
  else return imgSrc;
}

export function imgAltFromSlug(slug) {
  return slug.replace(/-/g, ' ');
}

// required for SSR otherwise SSR build fails
export function isBrowser() {
  return typeof window !== 'undefined';
}

export function getCanonicalUrl(siteUrl, slug) {
  return slug ? siteUrl + '/' + slug : siteUrl;
}
