import styled from '@emotion/styled';

const linkColor = '#bbb';
const linkHoverColor = '#fc0';

export default styled.div`
  display: flex;
  flex-direction: column;

  a {
    border: none;
    color: ${linkColor};

    &:hover {
      color: ${linkHoverColor};
    }

    &.active {
      color: #fc0;
      opacity: 0.6;
    }
  }
`;

export const MenuLink = styled.span`
  color: ${linkColor};
  cursor: pointer;
  font-weight: 400;

  &:hover {
    color: ${linkHoverColor};
  }
`;
