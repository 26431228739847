import React, { useRef, useEffect } from 'react';
import { Link } from "gatsby";
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { MOBILE_HEADER_MAX_WIDTH } from '../../common/constants';
import MenuColumn, { MenuLink } from './column';
import MenuHeading from './heading';
import BRANDS from '../../../data/brands.json';

const MenuLinkExtended = styled(MenuLink)`
  padding-bottom: 0.2rem;

  // &:last-of-type {
  //   padding-bottom: 0;
  // }
`;

const MenuLinkBack = styled(MenuLink)`
  display: block;
  margin-bottom: 1rem;
`;

const MenuColumnMobile = styled(MenuColumn)`
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const SubMenuMobile = styled.div`
  background: #555;
  position: absolute;
  top: 20px;
  left: 100%;
  right: 20px;
  bottom: 20px;
  overflow-y: auto;
  transition: left 300ms;

  &.open {
    left: 20px;
  }
`;

export default () => {
  const menuRef = useRef(null);
  let buttonRef = useRef(null);
  const ref = {
    categories: useRef(null),
    brands: useRef(null),
  };

  function hambergerClick(e) {
    const button = e.currentTarget;
    const menu = menuRef.current;
    if (button.classList.contains('open')) {
      button.classList.remove('open');
      button.classList.add('close');
      menu.classList.remove('open');
    } else {
      button.classList.remove('close');
      button.classList.add('open');
      menu.classList.add('open');
    }
  }

  function onMediaQueryChange(e) {
    const menu = menuRef.current;
    const button = buttonRef.current;
    if (e.matches && button) {
      button.classList.remove('open');
      button.classList.add('close');
      menu.classList.remove('open');
    }
  }

  function menuLinkClick(cardId) {
    const elm = ref[cardId].current;
    elm.classList.add('open');
  }

  function backToMainMenu(e) {
    const menuToClose = e.currentTarget.parentElement;
    menuToClose.classList.remove('open');
  }

  useEffect(() => {
    const mql = window.matchMedia(`(min-width: ${MOBILE_HEADER_MAX_WIDTH + 1}px)`);
    try {
      mql.addEventListener('change', onMediaQueryChange);
    } catch (e) {
      mql.addListener(onMediaQueryChange);
    }
  }, []);

  return (
    <section>
      <button ref={buttonRef} className="close" onClick={hambergerClick} css={css`
        background: none;
        border: none;
        cursor: pointer;
        display: none;
        padding: 0;
        position: relative;
        outline: none;
        height: 30px;
        width: 30px;

        > svg {
          position: absolute;
          transition: opacity 350ms;
        }

        &.close {
          #icon-hamburger {
            visibility: visible;
            opacity: 1;
          }
          #icon-cross {
            visibility: hidden;
            opacity: 0;
          }
        }

        &.open {
          #icon-hamburger {
            visibility: hidden;
            opacity: 0;
          }
          #icon-cross {
            visibility: visible;
            opacity: 1;
          }
        }

        @media (max-width: ${MOBILE_HEADER_MAX_WIDTH}px) {
          display: block;
        }
      `}>
        <svg id="icon-hamburger" viewBox="0 0 20 16" role="presentation" css={css`
          color: #999;
          left: 0;
          top: 6px;
          height: 16px;
          width: 26px;
        `}>
          <path d="M0 14h20v2H0v-2zM0 0h20v2H0V0zm0 7h20v2H0V7z" fill="currentColor" fillRule="evenodd"></path>
        </svg>
        <svg id="icon-cross" viewBox="0 0 19 19" role="presentation" css={css`
          left: 4px;
          top: 4px;
        `}>
          <path d="M9.1923882 8.39339828l7.7781745-7.7781746 1.4142136 1.41421357-7.7781746 7.77817459 7.7781746 7.77817456L16.9705627 19l-7.7781745-7.7781746L1.41421356 19 0 17.5857864l7.7781746-7.77817456L0 2.02943725 1.41421356.61522369 9.1923882 8.39339828z" fill="currentColor" fillRule="evenodd"></path>
        </svg>
      </button>

      <div ref={menuRef} css={css`
        background: #555;
        color: #bbb;
        position: fixed;
        top: 73px;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        transition: opacity 350ms;

        &.open {
          opacity: 1;
          visibility: visible;
        }
      `}>
        <svg viewBox="0 0 20 9" role="presentation" css={css`
          position: absolute;
          top: -9px;
          left: 31px;
          width: 29px;
          height: 9px;
        `}>
          <path d="M.47108938 9c.2694725-.26871321.57077721-.56867841.90388257-.89986354C3.12384116 6.36134886 5.74788116 3.76338565 9.2467995.30653888c.4145057-.4095171 1.0844277-.40860098 1.4977971.00205122L19.4935156 9H.47108938z" fill="#555555"></path>
        </svg>

        <div css={css`
          position: absolute;
          top: 20px;
          left: 20px;
          right: 20px;
          bottom: 20px;
        `}>
          <MenuColumnMobile>
            <MenuLinkExtended onClick={e => menuLinkClick('categories')}>Categories</MenuLinkExtended>
            <MenuLinkExtended onClick={e => menuLinkClick('brands')}>Brands</MenuLinkExtended>
            <Link to="/whats-new">What's New</Link>
          </MenuColumnMobile>
        </div>

        <SubMenuMobile ref={ref.categories}>
          <MenuLinkBack onClick={backToMainMenu}>
            &lt; Back
          </MenuLinkBack>
          <MenuColumnMobile>
            <MenuHeading>Washing</MenuHeading>
            <Link activeClassName="active" to="/category/decontamination">Decomtamination</Link>
            <Link activeClassName="active" to="/category/shampoos">Shampoos</Link>
            <Link activeClassName="active" to="/category/towels-mitts-brushes">Towels</Link>
          </MenuColumnMobile>

          <MenuColumnMobile>
            <MenuHeading>Exterior</MenuHeading>
            <Link activeClassName="active" to="/category/coatings">Coatings</Link>
              <Link activeClassName="active" to="/category/quick-detailers-spray-waxes">Quick Detailers &amp; Spray Waxes</Link>
              <Link activeClassName="active" to="/category/waxes-sealants">Waxes &amp; Sealants</Link>
              <Link activeClassName="active" to="/category/glass">Glass</Link>
              <Link activeClassName="active" to="/category/exterior-trim-rubber">Plastic &amp; Rubber</Link>
              <Link activeClassName="active" to="/category/headlight">Headlight Restoration &amp; Protection</Link>
              <Link activeClassName="active" to="/category/metal-polishes">Metal Polishes</Link>
              <Link activeClassName="active" to="/category/wheel-tire-cleaners">Wheel Cleaners</Link>
          </MenuColumnMobile>

          <MenuColumnMobile>
            <MenuHeading>Interior</MenuHeading>
              <Link activeClassName="active" to="/category/interior-cleaners">Interior Cleaners</Link>
              <Link activeClassName="active" to="/category/interior-protectants">Interior Protectants</Link>
              <Link activeClassName="active" to="/category/leather-fabric-care">Leather</Link>
          </MenuColumnMobile>

          <MenuColumnMobile>
            <MenuHeading>Polishing</MenuHeading>
            <Link activeClassName="active" to="/category/compounds-polishes">Compounds &amp; Polishes</Link>
          </MenuColumnMobile>

          <MenuColumnMobile>
            <MenuHeading>Repair</MenuHeading>
            <Link activeClassName="active" to="/category/repair">Paint Touchup</Link>
          </MenuColumnMobile>
        </SubMenuMobile>

        <SubMenuMobile ref={ref.brands}>
          <MenuLinkBack onClick={backToMainMenu}>
            &lt; Back
          </MenuLinkBack>

          <MenuColumnMobile>
            {Object.entries(BRANDS).map(([key, brand]) => (
              <Link key={key} to={`/brand/${key}`}>{brand.title}</Link>
            ))}
          </MenuColumnMobile>
        </SubMenuMobile>
      </div>

    </section>
  );
};
