import { isBrowser } from "./utils";

function findBySlug(slug, list) {
  return list.find(p => p.slug === slug);
}

function findBySlugAndColor(slug, color, list) {
  return list.find(p => p.slug === slug && (!p.color || p.color === color));
}

export function getShippingCost(cart = getCart()) {
  // return cart.length ? 300 : 0;
  return 0;
}

export function getCart() {
  if (typeof localStorage === 'undefined') return [];
  let cart = localStorage.getItem('cart');
  return cart ? JSON.parse(cart) : [];
}

function setCart(cart) {
  if (typeof localStorage !== 'undefined') {
    localStorage.setItem('cart', JSON.stringify(cart));
  }
}

function triggerAddToCartEvent(count) {
  if (!isBrowser()) return;

  window.dispatchEvent(
    new CustomEvent('cart:add', { detail: { count } })
  );
}

function computeTotalQty(cart) {
  return cart.reduce((total, product) => total + product.qty, 0);
}

export function getTotalQtyInCart() {
  return computeTotalQty(getCart());
}

export function getTotalPrice() {
  const cart = getCart();
  return cart.reduce((total, product) => total + product.qty * product.price, 0);
}

export function updateProductQty(product, qty) {
  const cart = getCart();
  const cartItem = findBySlugAndColor(product.slug, product.color, cart);
  if (cartItem) cartItem.qty = qty;
  setCart(cart);
  triggerAddToCartEvent(computeTotalQty(cart));
}

export function addToCart(product) {
  const cart = getCart();
  const existingProduct = findBySlugAndColor(product.slug, product.color, cart);
  if (existingProduct) {
    // mutating object in cart so no need
    // to add or replace in cart
    existingProduct.qty += product.qty;
  } else {
    cart.push(product);
  }
  setCart(cart);
  triggerAddToCartEvent(computeTotalQty(cart));
}

export function removeFromCart(product) {
  const cart = getCart();
  const cartItem = findBySlugAndColor(product.slug, product.color, cart);
  const index = cart.indexOf(cartItem);
  cart.splice(index, 1);
  setCart(cart);
  triggerAddToCartEvent(computeTotalQty(cart));
}

export function clearCart() {
  setCart([]);
  triggerAddToCartEvent(0);
}
